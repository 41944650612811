import React, { useEffect } from "react"
import FacilityData from "../../../contents/facilities.yaml"
const WhyChooseUs = () => {
  useEffect(() => {
    const { $ } = window
    $(".why-choose-us-slides").owlCarousel({
      loop: true,
      nav: true,
      dots: true,
      autoplayHoverPause: true,
      autoplay: false,
      items: 1,
      animateOut: "fadeOut",
      navText: [
        "<i class='flaticon-back'></i>",
        "<i class='flaticon-next-1'></i>",
      ],
    })
  }, [])

  let majorFacilities = FacilityData.contents.filter(f => f.major == true)
  if (majorFacilities.length > 4) majorFacilities = majorFacilities.slice(0, 4)
  return (
    <section className="why-choose-us-area">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="why-choose-us-content">
              <div className="content" style={{ paddingLeft: 10 }}>
                <span className="sub-title">Why You Choose Us</span>
                <h2>Services and Facilities at a Glance</h2>
                <p>
                  The services and facilities at the premises meets superspeciality standards
                  so that we can offer utmost care by early dignosis and high throughput treatments
                </p>

                <ul className="features-list">
                  {majorFacilities.map((f, i) => (
                    <li key={i}>
                      <div>
                        <i className={f.icon}></i>
                        <span>{f.title}</span>
                        {f.description_short}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="divider"></div>
            <div className="why-choose-us-slides owl-carousel owl-theme">
              <div className="why-choose-us-image bg1">
                <img src="/img/why-choose-img1.jpg" alt="image" />
              </div>

              <div className="why-choose-us-image bg2">
                <img src="/img/why-choose-img2.jpg" alt="image" />
              </div>

              <div className="why-choose-us-image bg3">
                <img src="/img/why-choose-img3.jpg" alt="image" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhyChooseUs
