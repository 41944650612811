import React from "react"
import DepartmentCaousel from "./DepartmentCarousel"
import FacilityData from "../../../contents/facilities.yaml"
const FacilitiesSection = () => {
  let highlightedFacilities = FacilityData.contents.filter(
    f => f.include_at_hompage == true
  )
  if (highlightedFacilities.length > 4)
    highlightedFacilities = highlightedFacilities.slice(0, 4)
  return (
    <section className="services-area ptb-100">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5 col-md-12">
            <div className="services-title-content">
              <span className="sub-title">Departments and Facilities</span>
              <h2>Major Departments and Facilities at Nemcare</h2>
              <p>
                We are in process of setting up the super specialities of with
                modern equipments, state of the art technology at minimum
                possible cost.
              </p>

              <ul className="features-list">
                <li>
                  <i className="fas fa-check"></i> Resident Doctors and Medical
                  Staff
                </li>
                {highlightedFacilities.map((f, i) => (
                  <li key={i}>
                    <i className="fas fa-check"></i> {f.title}
                  </li>
                ))}
              </ul>

              <a href="/facilities" className="btn btn-primary">
                Learn More <i className="flaticon-next"></i>
              </a>
            </div>
          </div>

          <div className="col-lg-7 col-md-12">
            <DepartmentCaousel />
          </div>
        </div>
      </div>
    </section>
  )
}

export default FacilitiesSection
