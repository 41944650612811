import React, { useReducer } from "react"
import Select from "react-select"
import Departments from "../../contents/departments.yaml"
import DoctorsData from "../../contents/doctors.yaml"

const DoctorSelect = () => {
  let dpts = Departments.contents.sort(function (a, b) {
    if (a.name < b.name) {
      return -1
    }
    if (a.name > b.name) {
      return 1
    }
    return 0
  })

  const [doctors, dispatch] = useReducer((state, change) => {
    return DoctorsData.filter(d => d.department_slug == change.value)
  }, null)

  console.log(doctors)
  return (
    <section>
      <div className="container">
        <div className={"row"}>
          <div className={"col-12"} style={{ zIndex: 1000 }}>
            <Select
              options={dpts.map(d => ({ value: d.slug, label: d.name }))}
              onChange={e => {
                dispatch(e)
              }}
              placeholder={"Select a Department to view the Doctors"}
            />

            <ul className={"doctor-search-result-list"}>
              {doctors ? (
                doctors.length > 0 ? (
                  doctors.map((d, i) => (
                    <li key={i}>
                      <span className="marker">
                        <i className="fas fa-check"></i>
                      </span>

                      <span className="details">
                        <strong>{d.name}</strong>, &nbsp;
                        <small>
                          {d.designation ? `${d.designation} - ` : ``}{" "}
                          {d.department}{" "}
                        </small>
                        <br />
                        <span>{d.schedule}</span>
                      </span>
                    </li>
                  ))
                ) : (
                  <li>No Results Found</li>
                )
              ) : null}
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DoctorSelect
