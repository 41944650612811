import React, { useEffect } from "react"
import DepartmentData from "../../../contents/departments.yaml"
import cliTruncate from 'cli-truncate'
const DepartmentCaousel = () => {
  useEffect(() => {
    const { $ } = window
    $(".department-slides").owlCarousel({
      loop: true,
      //nav: true,
      dots: true,
      autoplayHoverPause: true,
      autoplay: false,
      items: 1,
      animateOut: "slideOutLeft",
      //   navText: [
      //     "<i className='flaticon-back'></i>",
      //     "<i className='flaticon-next-1'></i>",
      //   ],
    })
  }, [])

  let i,
    j,
    data = [],
    chunk = 4
  for (i = 0, j = DepartmentData.contents.length; i < j; i += chunk) {
    data.push(DepartmentData.contents.slice(i, i + chunk))
    // do whatever
  }


  return (
    <div className="department-grid-carousel">
      <div className="department-slides owl-carousel owl-theme">
        {data.map((dg, gi) => (
          <div key={`dpt_group_${gi}`} className="department-grid-4">
            <div className={"slide-content"}>
              <div className="row m-0" style={{ paddingBottom: 50 }}>
                {dg.map((d, i) => (
                  <div
                    key={`dpt_grid_${i}`}
                    className="col-lg-6 col-md-6 col-sm-6 p-0"
                  >
                    <div className="single-services-box department-slide-item">
                      <h3
                        className={"d-block align-items-center"}
                        style={{ minHeight: 60 }}
                      >
                        <i className={d.icon}></i> {d.name}
                      </h3>
                      <p>{cliTruncate(d.content_short, 100)}</p>

                      <a href="#" className="read-more-btn" data-bs-toggle="modal" data-bs-target={`#modal-${d.slug}`}>
                        Read More
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
      {
        DepartmentData.contents.map((d, i) => (
          <div key={i} className="modal fade" id={`modal-${d.slug}`} tabIndex="-1" role="dialog" aria-labelledby={`modal-${d.slug}-title`} aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id={`modal-${d.slug}-long-title`}>
                    {/* <i className={d.icon}></i> &nbsp; */}
                    Department of {d.name}
                  </h5>
                </div>
                <div className="modal-body"> {d.content_long} </div>
                <div className="modal-footer">
                  <button type="button" className="modal-footer-button" data-bs-dismiss="modal">Close</button>
                </div>
              </div>
            </div>
          </div>
        ))
      }
    </div>
  )
}

export default DepartmentCaousel
