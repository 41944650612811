import React from "react"
const FacilitiesCounts = () => {
  return (
    <section className="funfacts-area ptb-100 bg-043d72">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5 col-md-12">
            <div className="funfacts-content">
              <span className="sub-title">Services At a Glance</span>
              <h2>Learn More About Our Success Stories</h2>
            </div>
          </div>

          <div className="col-lg-7 col-md-12">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-6 col-sm-3">
                <div className="single-funfacts">
                  <h3>
                    <span className="odometer" data-count="858">
                      12000
                    </span>
                    <sup>+</sup>
                  </h3>
                  <p>Happy Patients</p>
                </div>
              </div>

              <div className="col-lg-3 col-md-3 col-6 col-sm-3">
                <div className="single-funfacts">
                  <h3>
                    <span className="odometer" data-count="55">
                      13
                    </span>
                    <sup>+</sup>
                  </h3>
                  <p>Departments</p>
                </div>
              </div>

              {/* <div className="col-lg-3 col-md-3 col-6 col-sm-3">
                <div className="single-funfacts">
                  <h3>
                    <span className="odometer" data-count="800">
                      85
                    </span>
                    <sup>+</sup>
                  </h3>
                  <p>Capacity</p>
                </div>
              </div> */}

              <div className="col-lg-3 col-md-3 col-6 col-sm-3">
                <div className="single-funfacts">
                  <h3>
                    <span className="odometer" data-count="15">
                      53
                    </span>
                    <sup>+</sup>
                  </h3>
                  <p>Facilities</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FacilitiesCounts
